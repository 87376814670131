<template>
  <div class="home_page" @dblclick="toggle">
    <div class="kv">
      <video
        class="kv_video"
        autoplay
        loop
        muted
        :poster="require(`@/assets/images/home/kv.png`)"
      >
        <source src="@/assets/images/home/kv-video.mp4" type="video/mp4" />
      </video>
    </div>

    <div class="kv_box">
      <div class="tips">Double click to enter or exit full screen mode</div>

      <div class="kv_content">
        <figure class="logo">
          <img src="@/assets/images/home/logo.png" alt="" />
        </figure>
        <div class="card_box">
          <figure class="card_img" @click="toProjectEvent">
            <img src="@/assets/images/home/kv1.png" alt="" />
            <img
              src="@/assets/images/home/kv1_1.png"
              class="hot_area area1"
              alt=""
            />
          </figure>
          <figure class="card_img" @click="toVideoEvent">
            <img src="@/assets/images/home/kv2.png" alt="" />
            <img
              src="@/assets/images/home/kv2_1.png"
              class="hot_area area2"
              alt=""
            />
          </figure>
          <figure class="card_img" @click="toPdfEvent">
            <img src="@/assets/images/home/kv3.png" alt="" />
            <img
              src="@/assets/images/home/kv3_1.png"
              class="hot_area area3"
              alt=""
            />
          </figure>
          <figure class="card_img" @click="toWebViewEvent">
            <img src="@/assets/images/home/kv4.png" alt="" />
            <img
              src="@/assets/images/home/kv4_1.png"
              class="hot_area area4"
              alt=""
            />
          </figure>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  async created() {},
  mounted() {},
  computed: {},
  methods: {
    toProjectEvent() {
      this.$router.push("/project");
    },
    toVideoEvent() {
      this.$router.push("/video");
    },
    toWebViewEvent() {
      window.location.href = "https://form.typeform.com/to/KmwdnpMx";
    },
    toPdfEvent() {
      this.$router.push("/pdf");
    },
    toggle() {
      // const element = document.documentElement; //获取整个页面元素
      // if (document.fullscreenElement) {
      //   // 判断是否处于全屏状态
      //   if (document.exitFullscreen) {
      //     document.exitFullscreen();
      //   } else if (element.mozCancelFullScreen) {
      //     /* Firefox */
      //     document.mozCancelFullScreen();
      //   } else if (document.webkitExitFullscreen) {
      //     /* Chrome, Safari and Opera */
      //     document.webkitExitFullscreen();
      //   } else if (document.msExitFullscreen) {
      //     /* IE/Edge */
      //     document.msExitFullscreen();
      //   }
      // } else {
      //   if (element.requestFullscreen) {
      //     element.requestFullscreen();
      //   } else if (element.mozRequestFullScreen) {
      //     /* Firefox */
      //     element.mozRequestFullScreen();
      //   } else if (element.webkitRequestFullscreen) {
      //     /* Chrome, Safari & Opera */
      //     element.webkitRequestFullscreen();
      //   } else if (element.msRequestFullscreen) {
      //     /* IE/Edge */
      //     element.msRequestFullscreen();
      //   }
      // }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "../assets/scss/_fun.scss";
.home_page {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  .kv {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .kv_video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .kv_box {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: vw(190);
    .tips {
      position: fixed;
      top: vw(80);
      color: #fff;
      font-size: 16px !important;
      font-family: Helvetica;
      opacity: 0.5;
    }
    .kv_content {
      width: vw(1800);
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .logo {
        width: vw(639);
        margin-bottom: vw(60);
      }
      .card_box {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .card_img {
          position: relative;
          width: vw(418);
        }
        .hot_area {
          position: absolute;
          height: vw(50);
          right: vw(42);
          bottom: vw(42);
          z-index: 1;
        }
        .area1 {
          width: vw(204);
        }
        .area2 {
          width: vw(164);
        }
        .area3 {
          width: vw(146);
        }
        .area4 {
          width: vw(138);
        }
      }
    }
  }
}

.home_page {
  // min-width: 1200px 表示当屏幕宽度大于等于800px时，该样式生效。
  // max-width: 1200px 表示当屏幕宽度小于等于600px时，该样式生效。
  @media screen and (max-width: 1200px) {
    .kv_box {
      padding-top: 150px;
    }
  }
}
</style>
